import {atom} from 'recoil';
import { addDays } from 'date-fns';
//import { GuestsObject } from 'components/HeroSearchForm/type';

export interface GuestsObject {
    guestAdults?: number;
    guestChildren?: number;
    guestInfants?: number;
  }




export const isLoggedIn=atom({
    key:"isLoggedIn",
    default:JSON.parse(localStorage.getItem("isLoggedIn") ||'{}')
});

export const users=atom({
    key:"users",
    default:JSON.parse(localStorage.getItem("user") ||'{}')
    }

);


export const ticketsKids=atom({
    key:"ticketsKids",
    default:0
})

export const roomStays=atom({
    key:"roomStays",
    default:1
})

export const tickets=atom({
    key:"tickets",
    default:1
})

export const ticketsAdult=atom({
    key:"ticketsAdults",
    default:1
})




export const cartStateRoom=atom({
    key:"cartState",
    default:<any>[],

});

export const dateState=atom({
    key:"dateState",
    default:new Date()
})

export const dayState=atom({
    key:"numberDays",
    default:1
})

export const guestState=atom({
    key:"guestState",
    default:{
        adults:0,
        children:0
    }
})

export const endDateState=atom({
    key:"endDateState",
    default:addDays(new Date(), 7)
})

export const locatiom=atom({
    key:"location",
    default:""
})

// Stay filters state
export const rangeStays=atom({
    key:"rangeStays",
    default:<any>[500,1000000]
})

export const stayType=atom({
    key:"type",
    default:<any>[]
})

export const stayAmenities=atom({
    key:"amenities",
    default:<any>[]
})

export const stayTypes=atom({
    key:"types",
    default:""
})

export const stayAreas=atom({
    key:"areas",
    default:<any>[]
})

//End of stayfilter state


//#### start of Attraction Filter State

export const AttractionTypes=atom({
    key:"Attractiontypes",
    default:<any>[]
})




export const AttractionName=atom({
    key:"AttractionName",
    default:""
})

export const rangeAttraction=atom({
    key:"rangeAttraction",
    default:<any>[100,1000000]
})

//Rental car States

export const rangeCars=atom({
    key:"rangeCars",
    default:<any>[100,1000000]
})

export const cartypes=atom({
    key:"cartypes",
    default:<any>[]
})

export const CarExtras=atom({
    key:"CarExtas",
    default:<any>[]
})

//End Rental car States
export const nameAttraction=atom({
    key:"nameAttraction",
    default:""
})

export const locationStays=atom({
    key:"locationStays",
    default:""
})

export const tabStays=atom({
    key:"tabStays",
    default:""
})

export const tabTime=atom({
    key:"time",
    default:<any>[]
})

//#### End of Attractionfilter state